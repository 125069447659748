import '../stylesheets/Menu.scss';
import {useAuth} from "../../context/AuthContext";
import eventLogo from '../../assets/default/images/event-logo.png';

const Menu = ({step, setStep}) => {
  const { isAuthenticated } = useAuth();
  const queryParams = new URLSearchParams(window.location.search);
  const key = queryParams.get('key');

  return (
    <>
      <div className="menu-container">
        <img src={eventLogo} alt="Lexus - Electrified Games - Dallas 2004" />
        <p>Welcome to our new training simulation. Choose a training scenario below to get started.</p>
        <button type="button" onClick={() => window.location = '/role-play-landing'}>Determining Guest Needs</button>
        <button type="button" onClick={() => window.location = '/objection-landing'}>Overcoming Guest Objections 1</button>
        <button type="button" onClick={() => window.location = '/objection-advance-landing'}>Overcoming Guest Objections 2</button>
      </div>
      <div className={'end-modal-overlay' + (!isAuthenticated && !key ? ' visible' : '')}>
        <div className="end-modal d-flex flex-wrap">
          <span>We're sorry, but we were unable to create a user session.<br /><br />Please close this tab and try again.</span>
        </div>
      </div>
    </>
  );
};

export default Menu;
