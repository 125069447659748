import '../stylesheets/Evaluation.scss';
import eventLogo from '../../assets/default/images/event-logo.png';
import chevronRight from '../stylesheets/svg/chevron-right.svg';
import { useEffect, useCallback, useState } from 'react';
import { getLocal } from '../../utils/dataFetching';
import {rpModList} from "../../utils/constants";
import { sessionGetById } from "../../utils/session/session";
import {getEvalById} from "../../utils/fetch/llmFetching";
import { useLocation } from 'react-router-dom';
import {formattedDate, formatTimeWithZone} from "../../utils/helpers";
import {getEvaluation} from '../../utils/fetch/evaluationFetching'
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useErrorBoundary } from "react-error-boundary";

const Evaluation = ({step, setStep}) => {
  const { showBoundary } = useErrorBoundary();

  // Get dynamic URL part
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const evalIdCheck = pathSegments[pathSegments.length - 1];
  let evalId = '';

  if (evalIdCheck !== step) {
    evalId = evalIdCheck;
  }

  const cleanStep = step.replace(/-evaluation$/, "");
  
  const [rpSessionId] = useState(getLocal('rp_' + cleanStep + '_session_id'));
  let rpModId = rpModList[cleanStep].id;

  const [results, setResults] = useState('');
  const [resultsDate, setResultsDate] = useState('');
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const [evalLoaded, setEvalLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [evaluationContent, setEvaluationContent] = useState('');

  // Check if session id exists, look up instead
  const getSession = useCallback(async () => {
    try {

      let hasContent = false;
      
      // If evaluation id, check for existing and display
      if (evalId) {
        const evalResp = await getEvalById(evalId);
        if (evalResp?.data?.evaluation && evalResp?.data?.evaluation.content) {
          setResults(evalResp.data.evaluation.content);
          setResultsDate(`${formattedDate(evalResp.data.evaluation.updatedAt, true)} - ${formatTimeWithZone(evalResp.data.evaluation.updatedAt, true)}`);
          hasContent = true;
        }
      } else if (rpSessionId) {  
        // Check if session exists in DB
        const sessionRes = await sessionGetById(rpSessionId);
        if (sessionRes?.data?.session) {
          let s = sessionRes?.data?.session;
        
          if (s.module === rpModId) {
            
            // Check if anything is available to eval
            if (s.contents?.length > 0) {
              hasContent = true;
            }
            
          }
        }
        
      }



      if (hasContent === true) {
        setSessionLoaded(true);
      } else {
        window.location.href =  '/' + cleanStep;
      }
    } catch (e) {
      console.error('Error fetching session:', e);
      showBoundary(e);

    } finally {
      //setSessionLoaded(true); // Indicate that the session fetching is complete
    }
  }, [rpModId, rpSessionId, evalId, cleanStep]);

  useEffect(() => {
    getSession();
  }, [getSession]);

  const fetchEvaluationContent = useCallback(async () => {
    if (!evalLoaded) {
      try {

        const evalResp = await getEvaluation(rpSessionId, rpModId, results);
        
        setEvalLoaded(true);
        setIsLoading(false);

        let summary = evalResp;

        // //Report header
        // summary = summary.replace(/#{2,3} *(.*?)\n\n/, '<h6>$1</h6><br />');
        // //Total score
        // summary = summary.replace(/\*\*total score\*\* ([0-9]+ points)/i, '<h6>Total Score</h6><b>$1</b>');
        // //Report sections
        // summary = summary.replace(/\*\*(.*?)\*\*\n\n/g, '<h6>$1</h6>');
        // //Bullet point titles
        // summary = summary.replace(/\* *\*\*(.*?)\*\*/g, '<b>$1</b>');

        // summary = summary.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

        // setEvaluationContent(summary);



        // //Total score
        summary = summary.replace(/\*\*total score\*\* ([0-9]+ points)/i, '<h6>Total Score</h6><b>$1</b>');
        
        setEvaluationContent(summary);

        // return <div dangerouslySetInnerHTML={{ __html: summary }}></div>;
        //return <MarkdownPreview source={data} style={{ padding: 16 }} />
        return null;
      } catch (e) {
        //console.log('ERROR PARSING EVAL CONTENT',data.data.content,e);
        //return [];
        return null;
      }
    }
    return null;
  }, [evalLoaded, rpSessionId, rpModId, results]);


  useEffect(() => {
    if (sessionLoaded && !evalLoaded) {
      fetchEvaluationContent();
    }
  }, [sessionLoaded, evalLoaded, fetchEvaluationContent]);

  return (
    <div className="evaluation-container">
      <img src={eventLogo} alt="Lexus - Electrified Games - Dallas 2004" onClick={() => window.location = '/menu'} />
      
      <a className="not-mobile" href="/menu">&lt; menu</a>
      <a className="mobile" href="/menu">&lt; menu</a>

      <hr />
      <div className="evaluation-scrollable">
        <h3>Performance Evaluation</h3>
        {resultsDate && <p className='evalDate'>{resultsDate}</p>}
        {isLoading && "Loading..."}
        {evaluationContent && <MarkdownPreview className='testing' source={evaluationContent} />}
        {/* {evaluationContent && <div dangerouslySetInnerHTML={{ __html: evaluationContent }}></div>} */}
      </div>
    </div>
  );
};

export default Evaluation;
