import '../stylesheets/SummaryModules.scss';
import eventLogo from '../../assets/default/images/event-logo.png';
import { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {summaryRangeStart, summaryRangeEnd} from "../../utils/constants";
import { generateSummary, getSummaryModule, getSummariesByModuleId } from '../../utils/fetch/llmFetching'
import MarkdownPreview from '@uiw/react-markdown-preview';
import { useErrorBoundary } from "react-error-boundary";

const Summaries = ({ datestart, dateend }) => {
  const { showBoundary } = useErrorBoundary();

  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const moduleId = pathSegments[pathSegments.length - 1];

  const [module, setModule] = useState(null);
  const [summary, setSummary] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generateResponse, setGenerateResponse] = useState(null);

  const rangeStart = datestart || summaryRangeStart;
  const rangeEnd = dateend || summaryRangeEnd;

  const refresh = useCallback(async (moduleId, rangeStart, rangeEnd) => {
    try {
      setIsGenerating(true);

      const response = await generateSummary(moduleId, rangeStart, rangeEnd);
      
      setIsGenerating(false);
      setGenerateResponse(response);
    } catch (e) {
      showBoundary(e);
    }
  }, []);

  const getData = useCallback(async (moduleId) => {
    try {
      const moduleResponse = await getSummaryModule(moduleId);
      
      setModule(moduleResponse);

      const summariesResponse = await getSummariesByModuleId(moduleId);
      
      if (summariesResponse && summariesResponse.length) {
        const latestSummary = summariesResponse.sort((a, b) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime())[0];

        setSummary(latestSummary);
      } else {
        setSummary(null);
        
        await refresh(moduleId, rangeStart, rangeEnd);
      }
    } catch (e) {
     showBoundary(e); 
    }
  }, [refresh]);

  useEffect(() => {
    getData(moduleId);
  }, [getData, moduleId, generateResponse]);

  return (
    <div className="summaries-container">
      <img src={eventLogo} alt="Lexus - Electrified Games - Dallas 2004" onClick={() => window.location = '/menu'} />
      <a className="not-mobile" href="/session-summaries">&lt; Return to Dashboard</a>
      <a className="mobile" href="/session-summaries">&lt; Return</a>
      <hr />
      <div className="summaries-scrollable">
        <h3>Session Summary: {module && module.title}</h3>
        {isGenerating && <p><strong>Generating...</strong></p>}
        {!isGenerating && summary?.content && <MarkdownPreview source={summary.content} />}
      </div>
      <button className={'refresh' + (isGenerating ? ' disabled' : '')} disabled={isGenerating} type="button" onClick={() => refresh(moduleId, rangeStart, rangeEnd)}></button>
    </div>
  );
};

export default Summaries;
